import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export interface ContentBlockProps {
    header: string;
    children?: ReactNode;
}

export function ContentBlock({ header, children }: ContentBlockProps) {
    return (
        <>
            <Typography
                variant={'body1'}
                sx={{
                    fontWeight: 500,
                    fontSize: '18px',
                }}
            >
                {header}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: 2,
                }}
            >
                {children}
            </Typography>
        </>
    );
}
