import { styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { config } from '../config/Config';

export const HeaderLinkBig = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    fontSize: '24px',
    fontWeight: 500,
    marginTop: '-4px',
}));

export const HeaderLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    fontWeight: 400,
}));

export function MainHeader(): ReactElement {
    // always open

    return (
        <Box>
            <MuiAppBar position="fixed">
                <Container maxWidth="xl">
                    <Toolbar
                        sx={(theme) => ({
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            gap: theme.spacing(4),
                        })}
                    >
                        <HeaderLinkBig to={'/#start'}>
                            {config.portal.label}
                        </HeaderLinkBig>
                        <HeaderLink to={'/#plattform'}>
                            Die Plattform
                        </HeaderLink>
                        <HeaderLink to={'/#aboutUs'}>Über uns</HeaderLink>
                        <HeaderLink to={'/#module'}>Module</HeaderLink>
                        <HeaderLink to={'/#kontakt'}>Kontakt</HeaderLink>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </Box>
    );
}
