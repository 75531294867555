import { Config } from '../types/app/Config';

const profileKey: string = window.location.hostname;

export const serverApiProfiles: any = {
    localhost: 'http://localhost:8090',
};

const testSystems: string[] = ['localhost'];

export const config: Config = {
    app: {
        version: process.env.REACT_APP_VERSION + '',
    },
    portal: {
        label: 'Define Business',
        key: 'defb',
    },
    xhr: {
        staleTimeMainData: 10000,
        staleTimeReference: 10000,
        staleTimeLookUp: 10000,
    },
    opFlags: {
        enableTenants: true,
        flagTestsystem: testSystems.includes(profileKey),
        flagDebugMode: true,
        flagDebugNoLogin: true,
    },
};

export const DEBUG_NO_LOGIN = true;

export const BACKEND_BASE_API = serverApiProfiles[profileKey]
    ? serverApiProfiles[profileKey] + '/api/'
    : 'http://' + window.location.hostname + ':8090/api/';
