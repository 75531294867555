import { Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ModuleCard } from './components/ModuleCard';
import { SectionContent } from './components/SectionContent';
import { SectionHeader } from './components/SectionHeader';

export function ModuleSection({
    openDialogAction,
}: {
    openDialogAction: () => void;
}) {
    const theme = useTheme();

    return (
        <SectionContent anchor={'module'}>
            <Container maxWidth="xl">
                <SectionHeader>
                    Eine kleine Auswahl der verfügbaren Module
                </SectionHeader>

                <Grid container spacing={4}>
                    <ModuleCard
                        short={'CRM'}
                        title={'Kontakt und Kundenverwaltung'}
                        content={
                            <>
                                Unser "Contact Relation Management" Modul
                                ermöglicht es Ihnen verschiedenen Varianten von
                                Kontakten zu pflegen und die jeweils passenden
                                Geschäftsabläufe für die Kontaktvarianten zu
                                hinterlegen.
                                <br />
                            </>
                        }
                    />
                    <ModuleCard
                        short={'PM'}
                        title={'Projekt Management'}
                        content={
                            <>
                                Unser Projektmanagement Modul ermöglicht es
                                Ihnen alle Arten von Projekten und Abläufen zu
                                planen und zu koordinieren.
                            </>
                        }
                    />
                    <ModuleCard
                        short={'Procur'}
                        title={'Einkaufsverwaltung'}
                        content={
                            <>
                                Verwalten Sie Bestellungen und Lieferanten.
                                Behalten Sie den Überblick über
                                Preisentwicklungen und automatisieren sie
                                Abläufe im Bestellwesen.
                                <br />
                            </>
                        }
                    />
                    <ModuleCard
                        short={'Capa'}
                        title="Kapazitätenplanung"
                        content={
                            <>
                                Planen die die Ihnen zur Verfügung stehenden
                                Kapazitäten. Verknüpfen Sie die
                                Kapazitätenplanung mit beliebigen Daten in ihrem
                                System und behalten Sie Ihren Bedarf im Auge.
                            </>
                        }
                    />
                    <ModuleCard
                        short={'Budget'}
                        title={'Budget Planung & Tracking'}
                        content={
                            <>
                                Planen Sie Budgets für ihr Unternehmen. Das
                                Budgetplaner Modul ermöglicht es Ihnen Budgets
                                beliebigen Datensätzen zuzuordnen.
                                <br />
                                Des weiteren können beliebige Geldquellen wie
                                Fördertöpfe, Einnahmen, Rücklagen oder
                                Finanzierungen bei der Planung berücksichtigt
                                werden.
                            </>
                        }
                    />
                    <ModuleCard
                        short={'HR'}
                        title={'Mitarbeiter Verwaltung'}
                        content={
                            <>
                                Das HR Tool ermöglicht es Ihnen die Daten ihrer
                                Mitarbeiter sicher und zuverlässig zu verwalten.
                                Wählen Sie aus einer ganzen Reihe von
                                Funktionalitäten wie Vertragsmanagement,
                                Urlaubsplanung, Arbeitsplänen, Zielentwicklung
                                welche Daten Sie verwalten möchten.
                            </>
                        }
                    />
                    <ModuleCard
                        short={'Legal'}
                        title={'Vertragsdaten Verwaltung'}
                        content={
                            <>
                                Behalten Sie einen Überblick über Abmachungen /
                                Verträge die sie mit Partnern oder Gegner
                                geschlossen haben.
                                <br />
                                Verwalten sie NDAs, Mitarbeiter oder
                                Projektverträge und behalten Sie den Überblick
                                über alle Vertraglich zugesicherte Leistungen
                                ihres Unternehmens und eventuelle sich
                                anbahnende Risiken.
                            </>
                        }
                    />
                    <ModuleCard
                        short={'Facil'}
                        title={'Gebäudemanagement'}
                        content={
                            <>
                                Behalten sie den Überblick über ihre Gebäude.
                                <br />
                                Pflegen sie Kontaktpersonen und Dienstleister je
                                nach Standort und Vorgang. <br />
                                Verwalten Verträge, Mieter oder Untermieter.
                                <br />
                                Dokumentieren sie Vorfälle, Schäden,
                                Wartungsarbeiten oder sonstige relevante
                                Informationen zu ihren Immobilien.
                            </>
                        }
                    />
                </Grid>

                <Box marginTop={4}>
                    <Typography variant={'body1'} textAlign={'center'}>
                        Darüber hinaus haben wir noch eine ganze Reihe weiterer
                        Module im Angebot. <br />
                        Es ist jedoch unabhängig von den bereits vorhandenen
                        Modulen möglich eine komplett individuelle Software für
                        Sie zu definieren.
                        <br />
                        <Typography
                            component="span"
                            onClick={openDialogAction}
                            sx={{
                                fontWeight: '400',
                                color: theme.palette.primary.main,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            Kontaktiere Sie uns gerne.
                        </Typography>
                        <br />
                        Wir beraten Sie gerne welche Möglichkeiten es für
                        Anforderungen gibt.
                    </Typography>
                </Box>

                <Typography variant={'body1'} marginTop={6}></Typography>
            </Container>
        </SectionContent>
    );
}
