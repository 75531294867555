import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { MainFooter } from './MainFooter';
import { MainHeader } from './MainHeader';

export function MainContainer() {
    return (
        <>
            <CssBaseline />
            <MainHeader />
            <Box
                sx={{
                    minHeight: '100vh',
                    margin: 0,
                }}
            >
                <Outlet />
            </Box>

            <MainFooter />
        </>
    );
}
