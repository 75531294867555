import React from 'react';
import { Route } from 'react-router-dom';

import { MainContainer } from '../layout/MainContainer';
import { ImpressumPage } from '../pages/impressum/ImpressumPage';
import { StartPage } from '../pages/start/StartPage';

// Import your public routes and spread them inside the function
// This function will load protected routes and expose them in the application routes
export const publicRoutesIndex = () => [
    <Route element={<MainContainer />}>
        <Route key="startpage" path="/" element={<StartPage />} />
        <Route key="impressum" path="/impressum" element={<ImpressumPage />} />
    </Route>,
];
