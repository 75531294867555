import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export interface AvatarBlockProps {
    color: string;
    avatarContent: ReactNode;
    children: ReactNode;
}

export function AvatarBlock({
    color,
    avatarContent,
    children,
}: AvatarBlockProps) {
    return (
        <Box sx={{ width: '250px' }}>
            <Avatar
                sx={{
                    height: '100px',
                    width: '100px',
                    marginLeft: '75px',
                    backgroundColor: color,
                }}
            >
                {avatarContent}
            </Avatar>
            <Typography
                variant={'body1'}
                sx={{
                    marginTop: 2,
                    textAlign: 'center',
                }}
            >
                {children}
            </Typography>
        </Box>
    );
}
