import {
    Button,
    Card,
    CardContent,
    Grid,
    Link,
    ListItemText,
    Stack,
    TextField,
    styled,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ContactForm } from '../components/contact/ContactForm';

export const FooterListItem = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}));

export const FooterText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}));

export const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}));

export function MainFooter() {
    const theme = useTheme();

    const navigate = useNavigate();

    function linkClickAction(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        event.preventDefault();
        navigate('' + event.currentTarget.dataset.url);
    }

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.main,
                width: '100vw',
                marginTop: '-16px',
                minHeight: '300px',
                paddingBottom: '40px',
            }}
        >
            <Container maxWidth="xl">
                <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FooterText
                            variant={'h3'}
                            marginBottom={4}
                            marginTop={4}
                        >
                            Über uns
                        </FooterText>

                        <FooterText variant={'body1'} marginBottom={2}>
                            Define Business
                            <br />
                            Ein Produkt der Punitech GmbH
                            <br />
                            <br />
                            <br />
                            Software from real people for real people
                        </FooterText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FooterText
                            variant={'h3'}
                            marginBottom={4}
                            marginTop={4}
                        >
                            Adresse
                        </FooterText>

                        <FooterText variant={'body1'} marginBottom={2}>
                            Punitech GmbH
                            <br />
                            Kirrlacherstr. 23
                            <br />
                            68763 Waghäusel <br />
                        </FooterText>

                        <FooterText variant={'body1'} marginBottom={2}>
                            Tel: (+49) 176 23663693 / +49 176 35260384
                            <br />
                            Email: info@punitech.de
                        </FooterText>

                        <FooterLink
                            variant={'h3'}
                            marginBottom={2}
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            Datenschutz
                        </FooterLink>
                        <br />

                        <FooterLink
                            variant={'body1'}
                            marginBottom={2}
                        >
                            Warum habt ihr kein Cookie Banner?<br />
                            Wir speichern einfach keine Cookies oder sonstige Daten in ihrem Browser.
                        </FooterLink>
                        <br />
                        <FooterLink
                            variant={'body1'}
                            marginTop={2}
                            marginBottom={2}
                            sx={{
                                textDecoration: 'underline',
                            }}
                            onClick={linkClickAction}
                            data-url={'/impressum'}
                            href={'/impressum'}
                        >
                            Impressum
                        </FooterLink>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FooterText
                            id={'kontakt'}
                            variant={'h3'}
                            marginTop={4}
                            marginBottom={4}
                        >
                            Kontakt
                        </FooterText>

                        <Card
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                /*
                                background: 'rgba( 255, 255, 255, 0.5 )',
                                //boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                                backdropFilter: 'blur( 3px )',
                                border: '1px solid rgba( 255, 255, 255, 0.18 )',

                                 */
                            }}
                        >
                            <CardContent>
                                <ContactForm />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
