import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { publicRoutesIndex } from './publicRoutesIndex';

export function ApplicationRoutes() {
    return (
        <Routes>
            {publicRoutesIndex()}
            {/* if no routes match */}
            <Route path="*" element={<h1>No Match page</h1>} />
        </Routes>
    );
}
