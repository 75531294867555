import { Link, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

export interface SectionContentProps {
    children?: ReactNode;
    anchor?: string;
}

export function SectionContent({ children, anchor }: SectionContentProps) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                width: '100vw',
                marginBottom: theme.spacing(15),
            }}
        >
            {anchor != null && <Link id={anchor} />}

            {children}
        </Box>
    );
}
