import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

import { theme } from '../../config/ThemeOptions';

export function ImpressumPage() {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    marginTop: theme.spacing(8),
                }}
            >
                <Container maxWidth="xl">
                    <Helmet>
                        <meta
                            name="generator"
                            content="Impressum-Generator der Kanzlei Hensche Rechtsanwälte"
                        />
                    </Helmet>
                    <Typography
                        variant={'h1'}
                        sx={{
                            marginTop: '24px',
                            paddingTop: '24px',
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        Impressum
                    </Typography>
                    <Typography variant={'body1'}>
                        Punitech GmbH
                        <br />
                        Kirrlacherstr. 23
                        <br />
                        68753 Wagh&#228;usel
                        <br />
                        Tel: +49176/23663693
                        <br />
                        <a href="mailto:sales@punitech.de">sales@punitech.de</a>
                    </Typography>
                    <Typography variant={'body1'}>
                        <strong>Vertreten durch:</strong>
                    </Typography>
                    <Typography variant={'body1'}>
                        Dominik Bonsch (Gesch&#228;ftsf&#252;hrer)
                    </Typography>
                    <Typography variant={'body1'}>
                        <strong>Umsatzsteuer-ID:</strong>
                        <br />
                        Umsatzsteuer-Identifikationsnummer nach §27a
                        Umsatzsteuergesetz:
                        <br />
                        DE295132819
                    </Typography>
                    <Typography variant={'body1'}>&nbsp;</Typography>
                    <Typography variant={'body1'}>
                        <strong>Bildrechte:</strong>
                        <br />
                        <Link target="_blank" href="https://unsplash.com/">
                            https://unsplash.com/
                        </Link>
                    </Typography>

                    <Typography variant={'body1'}>&nbsp;</Typography>

                    <Typography variant={'h2'} marginBottom={2}>
                        Disclaimer - rechtliche Hinweise
                    </Typography>

                    <Typography variant={'h3'} marginBottom={1.5}>
                        Auskunfts- und Widerrufsrecht
                    </Typography>

                    <Typography variant={'body1'}>
                        Sie haben jederzeit das Recht, sich unentgeltlich und
                        unverz&#252;glich &#252;ber die zu Ihrer Person
                        erhobenen Daten zu erkundigen. Ebenfalls k&#246;nnen Sie
                        Ihre Zustimmung zur Verwendung Ihrer angegebenen
                        pers&#246;nlichen Daten mit Wirkung f&#252;r die Zukunft
                        widerrufen. Hierf&#252;r wenden Sie sich bitte an den im
                        Impressum angegebenen Diensteanbieter.
                    </Typography>
                    <Typography variant={'h3'} marginTop={2} marginBottom={1.5}>
                        Datenschutz (allgemein)
                    </Typography>
                    <Typography variant={'body1'} marginBottom={1}>
                        Beim Zugriff auf unsere Webseite werden automatisch
                        allgemeine Informationen (sog. Server-Logfiles) erfasst.
                        Diese beinhalten u.a. den von Ihnen verwendeten
                        Webbrowser sowie Ihr Betriebssystem und Ihren Internet
                        Service Provider. Diese Daten lassen keinerlei
                        R&#252;ckschl&#252;sse auf Ihre Person zu und werden von
                        uns statistisch ausgewertet, um unseren Internetauftritt
                        technisch und inhaltlich zu verbessern. Das Erfassen
                        dieser Informationen ist notwendig, um den Inhalt der
                        Webseite korrekt ausliefern zu k&#246;nnen.
                    </Typography>
                    <Typography variant={'body1'} marginBottom={1}>
                        Die Nutzung der Webseite ist grunds&#228;tzlich ohne
                        Angabe personenbezogener Daten m&#246;glich. Soweit
                        personenbezogene Daten (beispielsweise Name, Anschrift
                        oder E-Mail-Adressen) erhoben werden, erfolgt dies,
                        soweit m&#246;glich, stets auf freiwilliger Basis. Diese
                        Daten werden ohne Ihre ausdr&#252;ckliche Zustimmung
                        nicht an Dritte weitergegeben.
                    </Typography>
                    <Typography variant={'body1'} marginBottom={1}>
                        Sofern ein Vertragsverh&#228;ltnis begr&#252;ndet,
                        inhaltlich ausgestaltet oder ge&#228;ndert werden soll
                        oder Sie an uns eine Anfrage stellen, erheben und
                        verwenden wir personenbezogene Daten von Ihnen, soweit
                        dies zu diesem Zwecke erforderlich ist (Bestandsdaten).
                        Wir erheben, verarbeiten und nutzen personenbezogene
                        Daten soweit dies erforderlich ist, um Ihnen die
                        Inanspruchnahme des Webangebots zu erm&#246;glichen
                        (Nutzungsdaten). S&#228;mtliche personenbezogenen Daten
                        werden nur solange gespeichert wie dies f&#252;r den
                        genannten Zweck (Bearbeitung Ihrer Anfrage oder
                        Abwicklung eines Vertrags) erforderlich ist. Hierbei
                        werden steuer- und handelsrechtliche
                        Aufbewahrungsfristen von uns ber&#252;cksichtigt. Auf
                        Anordnung der zust&#228;ndigen Stellen m&#252;ssen wir
                        im Einzelfall Auskunft &#252;ber diese Daten
                        (Bestandsdaten) erteilen, soweit dies f&#252;r Zwecke
                        der Strafverfolgung, zur Gefahrenabwehr, zur
                        Erf&#252;llung der gesetzlichen Aufgaben der
                        Verfassungsschutzbeh&#246;rden oder des
                        Milit&#228;rischen Abschirmdienstes oder zur
                        Durchsetzung der Rechte am geistigen Eigentum
                        erforderlich ist.
                    </Typography>
                    <Typography variant={'body1'} marginBottom={1}>
                        Wir weisen ausdr&#252;cklich darauf hin, dass die
                        Daten&#252;bertragung im Internet (z. B. bei der
                        Kommunikation per E-Mail) Sicherheitsl&#252;cken
                        aufweisen kann. Vor dem Zugriff auf Daten kann nicht
                        l&#252;ckenlos gesch&#252;tzt werden.
                    </Typography>
                    <Typography variant={'body1'} marginBottom={1}>
                        Die Nutzung von im Rahmen der Impressumspflicht
                        ver&#246;ffentlichten Kontaktdaten durch Dritte zur
                        &#220;bersendung von nicht ausdr&#252;cklich
                        angeforderter Werbung und Informationsmaterialien wird
                        hiermit ausdr&#252;cklich untersagt. Ausgenommen hiervon
                        sind bestehende Gesch&#228;ftsbeziehungen bzw. es liegt
                        Ihnen eine entsprechende Einwilligung von uns vor.
                    </Typography>
                    <Typography variant={'body1'} marginBottom={1}>
                        Die Anbieter und alle auf dieser Website genannten
                        Dritten behalten sich ausdr&#252;cklich rechtliche
                        Schritte im Falle der unverlangten Zusendung von
                        Werbeinformationen vor. Gleiches gilt f&#252;r die
                        kommerzielle Verwendung und Weitergabe der Daten.
                    </Typography>
                    <Typography variant={'body1'} marginBottom={10}>
                        Dieses Impressum wurde mit Hilfe des{' '}
                        <a
                            target="_blank"
                            href="http://www.hensche.de/impressum-generator.html"
                        >
                            Impressum-Generators
                        </a>{' '}
                        von{' '}
                        <a
                            target="_blank"
                            href="http://www.hensche.de/Rechtsanwalt_Arbeitsrecht_Frankfurt.html"
                        >
                            HENSCHE Rechtsanw&auml;lte, Kanzlei f&uuml;r
                            Arbeitsrecht
                        </a>{' '}
                        erstellt.
                    </Typography>
                </Container>
            </Box>
        </>
    );
}
