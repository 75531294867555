import { Dialog, DialogContent, DialogTitle, Link } from '@mui/material';
import React from 'react';

import { ContactForm } from '../../components/contact/ContactForm';
import { useOpenDialog } from '../../logic/hooks/useOpenDialog';
import { AboutUsSection } from './components/AboutUsSection';
import { EndSection } from './components/EndSection';
import { HeaderSection } from './components/HeaderSection';
import { ModuleSection } from './components/ModuleSection';
import { PlattformSection } from './components/PlattformSection';

export function StartPage() {
    const { open, handleClickOpen, handleClose } = useOpenDialog();

    return (
        <>
            <Link id="start" />
            <HeaderSection />
            <PlattformSection openDialogAction={handleClickOpen} />
            <AboutUsSection />
            <ModuleSection openDialogAction={handleClickOpen} />
            <EndSection />

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Kontaktieren Sie uns</DialogTitle>
                <DialogContent>
                    <ContactForm />
                </DialogContent>
            </Dialog>
        </>
    );
}
