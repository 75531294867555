import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';

export interface MainHeaderProps {
    children: ReactNode;
}

export function MainHeader({ children }: MainHeaderProps) {
    return (
        <Typography
            variant="h2"
            sx={{
                fontSize: '42px',
                textAlign: 'center',
                fontWeight: '400',
            }}
            marginBottom={8}
        >
            {children}
        </Typography>
    );
}
