import { Button, Stack, TextField, useTheme } from '@mui/material';
import { useState } from 'react';

interface FormData {
    name: string;
    company: string;
    email: string;
    content: string;
}

export function ContactForm() {
    const theme = useTheme();

    const [formData, setFormData] = useState<FormData>({
        name: '',
        company: '',
        email: '',
        content: '',
    });

    const changeValueAction = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const sendFormAction = (event: any) => {
        const mailContent = `
Name: ${formData.name}
E-Mail: ${formData.email}
Firma: ${formData.company}

${formData.content}`;

        window.location.href =
            'mailto:contact@define.business?subject=Kontaktanfrage&body=' +
            encodeURI(mailContent);

        setFormData({
            name: '',
            company: '',
            email: '',
            content: '',
        });
    };

    return (
        <Stack spacing={2}>
            <TextField
                name="name"
                onChange={changeValueAction}
                label={'Ihr Name'}
                variant={'outlined'}
                required={true}
                fullWidth={true}
                InputProps={{
                    sx: {
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            />
            <TextField
                name="company"
                onChange={changeValueAction}
                label={'Firmen Name'}
                variant={'outlined'}
                fullWidth={true}
                InputProps={{
                    sx: {
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            />
            <TextField
                name="email"
                onChange={changeValueAction}
                label={'E-Mail'}
                required={true}
                variant={'outlined'}
                fullWidth={true}
                InputProps={{
                    sx: {
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            />
            <TextField
                name="content"
                onChange={changeValueAction}
                label={'Ihre Anfrage'}
                required={true}
                variant={'outlined'}
                multiline={true}
                fullWidth={true}
                InputProps={{
                    sx: {
                        backgroundColor: theme.palette.background.paper,
                        minHeight: '100px',
                    },
                }}
            />

            <Button variant={'outlined'} onClick={sendFormAction}>
                Jetzt abschicken
            </Button>
        </Stack>
    );
}
