import { Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AnchorLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ContentBlock } from './components/ContentBlock';
import { MainHeader } from './components/MainHeader';

export function PlattformSection({
    openDialogAction,
}: {
    openDialogAction: () => void;
}) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                width: '100vw',
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(12),
            }}
        >
            <AnchorLink id="plattform" />
            <Container maxWidth="xl">
                <MainHeader>Was ist Define Business?</MainHeader>

                <Grid container spacing={8}>
                    <Grid item xs={12} sm={6}>
                        <ContentBlock header="Der Defined Business Ansatz">
                            Define Business ist eine Softwareplattform die
                            darauf optimiert ist, Datenverwaltungssoftware aller
                            Art schnell und individuell nach Ihren Anforderungen
                            zu erstellen.
                        </ContentBlock>

                        <ContentBlock header="Was ist das Besondere an Define Business?">
                            Gerade bei Unternehmenssoftware sind Anforderungen
                            für bestimmte Abläufe innerhalb verschiedener Firmen
                            oft ähnlich. Aber eben nicht identisch.
                            <br />
                            Unsere Technologie ermöglicht es aus einer Vielzahl
                            von vorgefertigten Modulen die benötigten Funktionen
                            zu wählen und diese dann individuell auf ihre
                            Anforderungen anzupassen.
                        </ContentBlock>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <ContentBlock header="Mit der Definition der Anforderungen startet die Entwicklung">
                            Ein großer Teil der Anforderungen für ihre Anwendung
                            wird nicht herkömmlich programmiert sondern mit der
                            formal eindeutigen Sprache BDL (Business Description
                            Language) definiert. Die Anforderungen: "Was muss
                            gemacht werden?" werden komplett getrennt von der
                            Umsetzung: "Wie muss es umgesetzt werden?"
                            beschrieben. Diese Trennung von Anforderung und
                            Umsetzung ermöglicht es Software mit besonders hoher
                            Qualität, Performance und Stabilität zu erzeugen.
                            Des weiteren ist der Zeitaufwand zu Erstellung einer
                            Software (je nach Anforderungen) wesentlich kürzer
                            als bei einer herkömmlichen Individualentwicklung
                            oder bei Anpassungsprojekten einer Standardsoftware.
                        </ContentBlock>

                        {/*
                        <ContentBlock header="Die Plattform die gebaut wurde ihre Anforderungen umzusetzen">
                            Ihre Anforderungen sollten die Software definieren.
                            Nicht die Software ihr Unternehmen.
                            <br />
                            Individuelle Entwicklung ist aufwendig und teuer.
                            Aber auch das Anpassen einer Standardsoftware kann
                            leicht den geplanten Budgetrahmen sprengen ohne je
                            in die Nähe des gewünschten Ergebnisses gekommen zu
                            sein.
                            <br />
                            Wir bieten ihnen einen neuen Weg bei der die
                            Individualisierung der Software Teil des Standards
                            ist. Die Standard Software 2.0.
                        </ContentBlock>
                        */}
                    </Grid>

                    <Grid item xs={12}>
                        <Box marginTop={4}>
                            <Typography variant={'body1'} textAlign={'center'}>
                                Sie benötigen eine Software für Ihr Unternehmen?{' '}
                                <Typography
                                    component="span"
                                    onClick={openDialogAction}
                                    sx={{
                                        fontWeight: '400',
                                        color: theme.palette.primary.main,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Kontaktieren Sie uns.
                                </Typography>
                                <br />
                                Wir machen Ihnen ein Angebot, dass Sie nicht
                                ablehnen werden.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <ContentBlock header="Effizienz und Vermeidung von Redundanzen">
                            Die Anwendung enthält genau die Funktionen welche
                            für die sie angeschafft und für welche Sie zuständig
                            ist.
                            <br />
                            Dadurch ist die Software kleiner, schneller,
                            leichter erweiterbar und bietet auch keine
                            Funktionen die nicht benutzt werden sollen.
                            <br />
                            Das verbessert die Benutzbarkeit und verringert den
                            Einlernaufwand für ihre Mitarbeiter und Kollegen.
                        </ContentBlock>

                        <ContentBlock header="Überblick und Transparenz">
                            Wissen sie noch welche Funktionen in Ihren
                            Anwendungen implementiert wurden?
                            <br />
                            Wann wurde es eingebaut und was war der genaue
                            Zweck?
                            <br />
                            Wer hat die Anforderungen dafür geliefert?
                            <br />
                            Es ist immer genau dokumentiert und in der Software
                            einsehbar welche Funktionalität vorhanden sind,
                            warum dieses vorhanden sind und wann diese vom wem
                            angefordert wurden.
                        </ContentBlock>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <ContentBlock
                            header={'Featurebased Lifecycle Management'}
                        >
                            Mit Define Business haben Sie den Überblick über die
                            Funktionalitäten ihrer Software. Ist die Funktion in
                            der aktuellen Umsetzung noch korrekt oder muss sie
                            angepasst werden?
                            <br />
                            Wie ist die Historie der Anforderungen eine
                            Funktionalität und wann wurde sie warum geändert?
                            <br />
                            Wird das Feature überhaupt noch benutzt / gebraucht?
                            <br />
                            Mit Define Business haben Sie die volle Kontrolle.
                            <br />
                        </ContentBlock>

                        <ContentBlock header={'Maximale Kosteneffizienz'}>
                            Nicht nur bei der Erstellung sondern auch im Betrieb
                            ist die Software von Define Business in Relation zur
                            gelieferten Funktionsumfang mehr als nur
                            konkurrenzfähig.
                            <br />
                            Sie bezahlen nur das was sie wirklich benötigen.
                            <br />
                            Dank BDL ist auch eine Migration auf neue
                            Softwareversionen in selbst bei einem sehr stark
                            individualisierten Projekt nur mit vergleichsweise
                            geringen Aufwänden und kosten verbunden.
                        </ContentBlock>
                    </Grid>
                </Grid>

                <Box marginTop={4}>
                    <Typography variant={'body1'} textAlign={'center'}>
                        Das klingt zu gut um Wahr zu sein?{' '}
                        <Typography
                            component="span"
                            onClick={openDialogAction}
                            sx={{
                                fontWeight: '400',
                                color: theme.palette.primary.main,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            Lassen Sie sich die unsere Möglichkeiten
                            Demonstrieren.
                        </Typography>
                        <br />
                        Wir freuen uns auf ihre Anfrage.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
