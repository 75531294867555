import Container from '@mui/material/Container';
import React from 'react';

import { FlexboxCenter } from '../../../components/container/Flexbox';
import { AvatarBlock } from './components/AvatarBlock';
import { SectionContent } from './components/SectionContent';
import { SectionHeader } from './components/SectionHeader';

export function AboutUsSection() {
    return (
        <SectionContent anchor={'aboutUs'}>
            <Container maxWidth="xl">
                <SectionHeader>
                    Software von Menschen für Menschen
                </SectionHeader>

                <FlexboxCenter>
                    <AvatarBlock color={'#671021'} avatarContent="20 Jahre">
                        Erfahrung in der Entwicklung von Unternehmenssoftware
                    </AvatarBlock>
                    <AvatarBlock color={'#57023d'} avatarContent="17 Jahre">
                        Entwicklung der Define Business Plattform
                    </AvatarBlock>
                    <AvatarBlock color={'#4f016e'} avatarContent="über 50">
                        Anzahl der Projekte in denen wir mitentwickelt oder die
                        wir komplett gebaut haben
                    </AvatarBlock>
                    <AvatarBlock color={'#3b06b0'} avatarContent="10 +">
                        Branchen für die wir Erfahrungen in der Entwicklung
                        gesammelt haben
                    </AvatarBlock>
                </FlexboxCenter>
            </Container>
        </SectionContent>
    );
}
