import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Grid,
    useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export interface ModuleCardProps {
    short: string;
    title: string;
    content: ReactNode;
}

export function ModuleCard({ short, title, content }: ModuleCardProps) {
    const theme = useTheme();

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
                sx={{
                    borderTop: '1px solid ' + theme.palette.divider,
                    width: '100%',
                    height: '100%',
                }}
                elevation={1}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{
                                backgroundColor: '#15273c',
                                width: '50px',
                                height: '50px',
                                fontSize: '14px',
                            }}
                        >
                            {short}
                        </Avatar>
                    }
                    title={title}
                    sx={{
                        '.MuiCardHeader-title': {
                            fontSize: '20px',
                        },
                    }}
                />

                <CardContent>
                    <Typography variant={'body1'}>{content}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}
