import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';

export interface SectionHeaderProps {
    children: ReactNode;
}

export function SectionHeader({ children }: SectionHeaderProps) {
    return (
        <Typography
            variant="h2"
            sx={{
                fontSize: '32px',
                textAlign: 'center',
                fontWeight: '400',
            }}
            marginBottom={8}
        >
            {children}
        </Typography>
    );
}
