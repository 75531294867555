import Box from '@mui/material/Box';
import React from 'react';

export function HeaderSection() {
    return (
        <Box
            sx={{
                backgroundImage: 'url(/assets/images/background/header.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'middle left',
                backgroundSize: '100% auto',
                height: '300px',
                width: '100vw',
                marginTop: '64px',
            }}
        ></Box>
    );
}
